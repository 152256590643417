import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import { Typography, Container } from '@mui/material';
import Layout from './Layout';
import { CirclesWithBar } from "react-loader-spinner";

function ServerActivatePage() {
  const [activate, setActivated] = useState(false);

  let { pk } = useParams();

  useEffect(() => {
    const sendActivate = async () => {
    try {
        const response = await axiosInstance.get(`/server/startlink/${pk}`, {authHeader: false});
        if (response.status === 200) {
            if (response.data.started === false) {
                setTimeout(() => {
                    setActivated(true);
                }, 5000);
                setTimeout(() => {
                    window.location.href = `${response.data.url}`;
                }, 7000);
            }
            else {
                window.location.href = `${response.data.url}`;
            }
        }
    } catch (error) {
          console.error('Ошибка при активации.', error);
      }
    }
    sendActivate();
  }, [pk]);

  return(
    <Layout>
        <Container maxWidth="lg" sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            {activate ? (
                <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Сервер активирован!
                </Typography>
                </>
            ) : (
                <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Запускаем сервер...
                </Typography>
                <CirclesWithBar color='#ff6465' />
                </>
            )}
        </Container>
    </Layout>
  );
}

export default ServerActivatePage;