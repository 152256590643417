import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import { Typography, Container } from '@mui/material';
import Layout from './Layout';


function ActivatePage() {
  const [activate, setActivated] = useState(false);

  let { pk } = useParams();

  useEffect(() => {
    const sendActivate = async () => {
    try {
        const response = await axiosInstance.get(`/user/activate/${pk}/`, {authHeader: false});
        if (response.status === 200) {
            setActivated(true);
        }
    } catch (error) {
          console.error('Ошибка при активации.', error);
      }
    }
    sendActivate();
  }, [pk]);

  return(
    <Layout>
        <Container maxWidth="lg" sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            {activate ? (
                <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Ваша учётная запись активирована!
                </Typography>
                </>
            ) : (
                <>
                <Typography variant="h5" component="h2" gutterBottom>
                    Активируем вашу учётную запись...
                </Typography>
                </>
            )}
        </Container>
    </Layout>
  );
}

export default ActivatePage;