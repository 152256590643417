import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { Circles } from "react-loader-spinner";
import ActivatePage from './Components/StaticComponents/ActivatePage';
import ResetPasswordPage from './Components/StaticComponents/ChangePass';
import PasswordResetPage from './Components/StaticComponents/PasswordResetPage';
import ServerActivatePage from './Components/StaticComponents/ServerActivatePage';

let theme = createTheme({
  palette: {
    background: {
      default: '#ffffff', // Основной фон
      paper: '#f4f4f4', // Фон бумажных элементов, таких как Card
    },
    primary: {
      main: '#ff6465', // Основной цвет
      contrastText: '#ffffff', // Текст на основном цвете для читаемости
    },
    secondary: {
      main: '#e65a5b', // Дополнительный цвет
      contrastText: '#ffffff', // Текст на дополнительном цвете для читаемости
    },
    error: {
      main: '#f44336', // Цвет для ошибок
    },
    warning: {
      main: '#ff9800', // Цвет для предупреждений
    },
    info: {
      main: '#2196f3', // Информационный цвет
    },
    success: {
      main: '#4caf50', // Цвет для успешных действий
    },
    text: {
      primary: '#333333', // Основной цвет текста
      secondary: '#757575', // Второстепенный цвет текста
      disabled: '#bdbdbd', // Цвет текста для неактивных элементов
    },
    divider: '#e0e0e0', // Цвет разделителей
    // Дополнительно можно определить другие цвета, если это необходимо
  },
  // Здесь можно добавить другие настройки темы (типографика, переопределения компонентов и т.д.)
});

theme = responsiveFontSizes(theme);

// Определение лениво загружаемых компонентов
const Login = lazy(() => import('./Components/Auth/Login'));
const Register = lazy(() => import('./Components/Auth/Register'));
const MainPage = lazy(() => import('./Components/Main/MainPage'));


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}>
            <Circles color="#e65a5b" height={100} width={100} />
          </div>
        }>
            <Routes>
              <Route path="/user/change-pass/:token" element={<ResetPasswordPage />} />
              <Route path="/user/activate/:pk" element={<ActivatePage />} />
              <Route path="/server/activate/:pk" element={<ServerActivatePage />} />
              <Route path="/password-reset/" element={<PasswordResetPage />} />
              <Route path="/" element={<MainPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
