import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

const Logout = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleLogout = () => {
    // Очистка localStorage
    localStorage.clear();
    // Скрыть модальное окно подтверждения и показать модальное окно успешного выхода
    setShowConfirmModal(false);
    setShowSuccessModal(true);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    window.location.href = '/';
  };

  return (
    <div>
      <Button color="inherit" onClick={() => setShowConfirmModal(true)} sx={{ textTransform: 'none' }}>Выйти</Button>
      <Modal
        open={showConfirmModal}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Уверены ли вы, что хотите выйти?
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleLogout} sx={{ mr: 1 }}>Да</Button>
            <Button variant="outlined" onClick={handleCancel}>Нет</Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box sx={style}>
          <Typography id="success-modal-title" variant="h6" component="h2">
            Вы успешно вышли из системы.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleCloseSuccessModal}>OK</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Logout;
