import axiosInstance from "./axiosConfig";

const TokenRefresh = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await axiosInstance.post('/user/token/refresh/', {"refresh": refreshToken}, {
        authHeader: false,
      });
  
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
  
      console.log('Успешное обновление токенов.');
    } catch (error) {
      console.error('Ошибка входа:', error);
    }
  }

export default TokenRefresh;