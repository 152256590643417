import React, { useState } from 'react';
import axiosInstance from '../../axiosConfig'; // Убедитесь, что путь к вашему axiosInstance верный
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import Layout from './Layout';

const PasswordResetPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [btnDisabled, setDisabled] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        try {
            // Предполагается, что ID пользователя извлекается на бекенде из email
            // Убедитесь, что ваш API поддерживает такую логику, или адаптируйте запрос соответствующим образом
            const response = await axiosInstance.post(`user/send_reset/`, {email: email}, {authHeader: false});
            setMessage('Ссылка для сброса пароля отправлена на ваш email.');
        } catch (error) {
            setMessage('Ошибка при отправке email. Пожалуйста, проверьте данные и попробуйте снова.');
        }
        finally {
            setDisabled(false);
        }
    };

    return (
        <Layout>
            <Container maxWidth="lg" sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Восстановление пароля
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            disabled={btnDisabled}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Отправить email
                        </Button>
                        {message && <Typography sx={{ mt: 2 }}>{message}</Typography>}
                    </Box>
                </Box>
            </Container>
        </Layout>
    );
};

export default PasswordResetPage;