import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://4tt.org:8008/api',
});

// Добавляем интерцептор запросов
axiosInstance.interceptors.request.use(
  config => {
    // Проверяем, не установлен ли флаг shouldAddAuthHeader явно в false
    if (config.authHeader !== false) { // Добавляем заголовок по умолчанию, если флаг не установлен или установлен в true
      const token = localStorage.getItem('accessToken');

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    // Если флаг явно установлен в false, заголовок не добавляем
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
