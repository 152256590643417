import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import { Button, TextField, Container, Typography, Box, Alert } from '@mui/material';
import Layout from './Layout';

const ResetPasswordPage = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Пароли не совпадают.');
            return;
        }

        try {
            await axiosInstance.post('/user/reset-password/', { token: token, newPassword: newPassword }, {authHeader: false});
            navigate('/login');
        } catch (error) {
            const response = error.response
            if (response.status !== 500 && response.status !== 200) {
                setError(response.data.error);
            }
        }
    };

    return (
        <Layout>
        <Container maxWidth="lg" sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Смена пароля
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="Новый пароль"
                        type={showPassword ? "text" : "password"}
                        id="newPassword"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                              <Button onClick={togglePasswordVisibility}>
                                {showPassword ? "Скрыть" : "Показать"}
                              </Button>
                            ),
                          }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Подтвердите пароль"
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                              <Button onClick={toggleConfirmPasswordVisibility}>
                                {showConfirmPassword ? "Скрыть" : "Показать"}
                              </Button>
                            ),
                          }}
                    />
                    {error && <Alert severity="error" sx={{ width: '93%', mt: 2 }}>{error}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Сменить пароль
                    </Button>
                </Box>
            </Box>
        </Container>
        </Layout>
    );
};

export default ResetPasswordPage;