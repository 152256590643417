import React from 'react';
import { Box } from '@mui/material';

const Footer = () => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      p={2} // Отступы внутри Box
      bgcolor="background.paper" // Фон футера, можно настроить через theme.palette
      color="text.secondary" // Цвет текста, можно настроить через theme.palette
    >
      <Box pr={2} // Дополнительный отступ справа, чтобы не упираться в край
      >
        © {new Date().getFullYear()} 4tt.org все права защищены.
      </Box>
    </Box>
  );
};

export default Footer;