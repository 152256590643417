import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import axiosInstance from '../../axiosConfig'; // Убедитесь, что axiosConfig правильно настроен
import Logout from '../Auth/Logout';
import TokenRefresh from '../../tokenRefresh';


const Header = () => {
  const [username, setUsername] = useState(null);
  const [isDataFetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        try {
          const response = await axiosInstance.get('/user/username/');
            setUsername(response.data.username);
          } catch (error) {
            console.log('Trying to refresh token')
            await TokenRefresh();
            try {
              const response = await axiosInstance.get('/user/username/');
              setUsername(response.data.username);
            } catch (error) {
              console.error('Ошибка при получении данных пользователя:', error);
              setUsername(null);
              if (error.response.status === 401) localStorage.clear();
            }
        }
      } else {
        setUsername(null);
      }
      setFetched(true);
    };
    fetchUserData();
  }, []);

  if (!isDataFetched) {
    return (
      <AppBar position="static" color="default" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Button component={RouterLink} to="/" color="inherit" sx={{ textTransform: 'none' }}>
              4TT.org
            </Button>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
      )
  }

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Button component={RouterLink} to="/" color="inherit" sx={{ textTransform: 'none' }}>
              4TT.org
            </Button>
          </Typography>
          {username ? (
            <>
                <Button component={RouterLink} to="/" sx={{ textTransform: 'none' }}>
                {username}
                </Button>
                <Logout></Logout>
            </>
          ) : (
            <>
              <Button component={RouterLink} to="/login" sx={{ textTransform: 'none' }}>
                Вход
              </Button>
              <Button component={RouterLink} to="/register" sx={{ textTransform: 'none' }}>
                Регистрация
              </Button>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
